import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.min.css";
import "../assets/css/style.css";
import { disableBack } from "../../common/Utility";
import Header from "../components/AdminHeader";
import Footer from "../components/AdminFooter";
import PersonalDetails from "../components/PersonalDetails";
import CvBuilderAdminService from "../services/CvBuilderAdminService";
import { useSearchParams } from "react-router-dom";

const AdminPersonal: React.FC = () => {
  const [params, setParams] = useSearchParams(window.location.pathname);

  React.useEffect(() => {
    disableBack();
  }, []);

  // setParams(window.location.pathname);

  return (
    <>
      <Header />
      <main id="main">
      <div className="container-fluid">        
        {params.get("code")}
        <PersonalDetails />
      </div>
      </main>
      <Footer />
    </>
  );
};

export default AdminPersonal;
