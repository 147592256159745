import React from "react";

const Footer: React.FC = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Souvik Laha</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Designed by <a href="http://www.nexverse.co.in">Nexverse</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
