import React from "react";

const PersonalDetails: React.FC = () => {
  return (
    <div className="row">
      {/* Profile Image */}
      <div className="col-md-2 col-lg-4 mt-4 mb-4 personal">
        <img
          className="border rounded profile-pic"
          src={require("./../assets/img/my-profile-pic.jpg")}
          alt="profile pic"
        />
      </div>

      {/* Personal Data */}
      <div className="col-md-10 col-lg-8 mt-4 mb-4">
        <form className="row g-3">
          <div className="col-md-6">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
            />
          </div>
          <div className="col-6">
            <label htmlFor="mobile" className="form-label">
              Mobile
            </label>
            <input
              type="text"
              className="form-control"
              id="mobile"
              placeholder="+919876543210"
              name="mobile"
            />
          </div>
          <div className="col-6">
            <label htmlFor="location" className="form-label">
              Location
            </label>
            <input
              type="text"
              className="form-control"
              id="location"
              placeholder="City, District, Country"
            />
          </div>
          <div className="col-md-12">
            <label htmlFor="uploadImage" className="form-label">
              Upload Image
            </label>
            <input className="form-control" type="file" id="uploadImage" name="image" />
          </div>
          <div className="col-md-12">
            <label htmlFor="tagLine" className="form-label">
              Tagline
            </label>
            <input
              type="text"
              className="form-control"
              id="tagLine"
              name="tagLine"
            />
          </div>
          <div className="col-md-12">
            <label htmlFor="summary" className="form-label">
              Summary
            </label>
            <textarea
              className="form-control"
              id="summary"
              rows={5}
              name="summary"
            ></textarea>
          </div>
          
          <div className="col-12">
            <button type="submit" className="btn btn-primary">
              <i className="bx bx-pencil"></i>&nbsp; Update Profile
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalDetails;
