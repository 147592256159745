import axios from "axios";
import { getLocalBaseUrl } from "../../common/Utility";
import { Constants } from "../../common/Constants";

function getLinkedInAccessToken(code: string | null) {
  let axiosConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    }
  };
  let url =
    "https://www.linkedin.com/oauth/v2/accessToken?code=" +
    code +
    "&grant_type=authorization_code&client_id=789d3ytnsvijon&client_secret=siwmaILlkXSAKiiI&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fadmin%2Fhome";

  return axios.post(url, null, axiosConfig);
}

function getResume() {
  return axios.get(
    getLocalBaseUrl + "/cv-builder-app/profile/" + Constants.PROFILE_ID
  );
}

const CvBuilderAdminService = {
  getLinkedInAccessToken,
  getResume,
};

export default CvBuilderAdminService;
