import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.min.css";
import "../assets/css/style.css";
import { disableBack } from "../../common/Utility";
import Header from "../components/AdminHeader";
import Footer from "../components/AdminFooter";
import Languages from "../components/Languages";
import { RouteLinks } from "../../common/RouteLinks";
import PersonalDetails from "../components/PersonalDetails";
import EducationalDetails from "../components/EducationalDetails";
import ProfessionalDetails from "../components/ProfessionalDetails";
import Achievements from "../components/Achievements";
import Hobbies from "../components/Hobbies";
import { useSearchParams } from "react-router-dom";
import CvBuilderAdminService from "../services/CvBuilderAdminService";

interface accessToken {
  access_token: string;
  expires_in: string;
  scope: string;
  token_type: string;
  id_token: string;
}

const AdminHomePage: React.FC = () => {
  const [path, setPath] = React.useState<string>();
  const [linkedInAuthorization, setLinkedInAuthorization] =
    React.useState<string>("");
  const [params, setParams] = useSearchParams(window.location.pathname);
  const [accessToken, setAccessToken] = React.useState<accessToken>();

  const setHeaderPath = (path: string) => {
    setPath(path);
    console.log(path);
  };
  React.useEffect(() => {
    disableBack();
    // connectWithLinkedIn();
    getLinkedInAccessToken();
  }, []);

  const connectWithLinkedIn = () => {
    setLinkedInAuthorization(
      "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=789d3ytnsvijon&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fadmin%2Fhome&state=fooobar&scope=r_emailaddress%20openid%20profile%20r_liteprofile%20w_member_social%20email"
    );
  };

  const getLinkedInAccessToken = () => {
    CvBuilderAdminService.getLinkedInAccessToken(params.get("code"))
      .then((response) => {
        setAccessToken(response.data);
      })
      .catch((err) => console.log(err));
  };
  console.log("params.get('code'): " + params.get("code"));
  console.log(
    "Access Token:: " + accessToken?.token_type + " " + accessToken?.access_token
  );
  return (
    <>
      <Header />
      <main id="main">
        <div className="container-fluid">
          {typeof accessToken?.id_token == "undefined" && (
            <a
              href={linkedInAuthorization}
              className="btn btn-primary mt-3"
              onClick={connectWithLinkedIn}
            >
              <i className="bx bxl-linkedin"></i> &nbsp; Connect with LinkedIn
            </a>
          )}
          {typeof params.get("code") != "undefined" && (
            <div>{accessToken?.token_type}&nbsp;{accessToken?.access_token}</div>
          )}
          {/* {path === RouteLinks.ADMIN_PERSONAL && <PersonalDetails />}
          {path === RouteLinks.ADMIN_EDUCATIONAL && <EducationalDetails />}
          {path === RouteLinks.ADMIN_PROFESSIONAL && <ProfessionalDetails />}
          {path === RouteLinks.ADMIN_ACHIEVEMENTS && <Achievements />}
          {path === RouteLinks.ADMIN_HOBBIES && <Hobbies />} */}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AdminHomePage;
