export function disableBack() {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.go(1);
  };
}

export function getProductionBaseUrl() {
  return "https://nexverse-api.onrender.com";
}

export function getLocalBaseUrl() {
  return "http://localhost:5000";
}
