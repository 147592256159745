import React from "react";
import { RouteLinks } from "../../common/RouteLinks";
import { useNavigate } from "react-router-dom";

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const logoutHandler = () => {
    navigate(RouteLinks.ADMIN_LOGIN);
  };
  return (
    <>
      <button className="btn btn-light" onClick={logoutHandler}>
        Logout
      </button>
    </>
  );
};

export default Logout;
