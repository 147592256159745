import { CountUp } from "countup.js";
import React from "react";
import { Waypoint } from "react-waypoint";

const Facts = () => {
  const countupRefClients = React.useRef<HTMLElement>(null);
  const countupRefProjects = React.useRef<HTMLElement>(null);
  const countupRefSupports = React.useRef<HTMLElement>(null);
  const countupRefAwards = React.useRef<HTMLElement>(null);
  let countUpAnimClients: CountUp;
  let countupAnimProjects: CountUp;
  let countupAnimSupports: CountUp
  let countupAnimAwards: CountUp;

  // dynamically import and initialize countUp, sets value of `countUpAnim`
  // you don't have to import this way, but this works best for next.js
  async function initCountUp() {
    const countUpModule = await import('countup.js');
    let clients = document.querySelector('.clients');
    let projects = document.querySelector('.projects');
    let supports = document.querySelector('.supports');
    let awards = document.querySelector('.awards');
    
    if (countupRefClients && countupRefClients.current && clients) {
      let endCounter = Number(clients.getAttribute('data-purecounter-end'));
      countUpAnimClients = new countUpModule.CountUp(countupRefClients.current, endCounter);
      if (!countUpAnimClients.error) {
        countUpAnimClients.start();
      } else {
        console.error(countUpAnimClients.error);
      }
    }

    if (countupRefProjects && countupRefProjects.current && projects) {
      let endCounter = Number(projects.getAttribute('data-purecounter-end'));
      countupAnimProjects = new countUpModule.CountUp(countupRefProjects.current, endCounter);
      if (!countupAnimProjects.error) {
        countupAnimProjects.start();
      } else {
        console.error(countupAnimProjects.error);
      }
    }

    if (countupRefSupports && countupRefSupports.current && supports) {
      let endCounter = Number(supports.getAttribute('data-purecounter-end'));
      countupAnimSupports = new countUpModule.CountUp(countupRefSupports.current, endCounter);
      if (!countupAnimSupports.error) {
        countupAnimSupports.start();
      } else {
        console.error(countupAnimSupports.error);
      }
    }

    if (countupRefAwards && countupRefAwards.current && awards) {
      let endCounter = Number(awards.getAttribute('data-purecounter-end'));
      countupAnimAwards = new countUpModule.CountUp(countupRefAwards.current, endCounter);
      if (!countupAnimAwards.error) {
        countupAnimAwards.start();
      } else {
        console.error(countupAnimAwards.error);
      }
    }
  }

  return (
    <>
      {/* <!-- ======= Facts Section ======= --> */}
      <section id="facts" className="facts">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Facts</h2>
            <p>
              I have worked in all aspects of Software Development, and hence I have got a chance to work with numerous 
              projects, clients. I have given support to the application a lot and I have been awarded too. Here is 
              small facts about my career during 9+ years of experience.
            </p>
          </div>
          <Waypoint onEnter={initCountUp}></Waypoint>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="count-box">
                <i className="bi bi-emoji-smile"></i>
                <span 
                  className="clients"
                  ref={countupRefClients}
                  data-purecounter-start="0"
                  data-purecounter-end="25"
                  data-purecounter-duration="1"
                >0</span>
                <p>Happy Clients</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
              <div className="count-box">
                <i className="bi bi-journal-richtext"></i>
                <span
                  className="projects"
                  ref={countupRefProjects}
                  data-purecounter-start="0"
                  data-purecounter-end="50"
                  data-purecounter-duration="1"
                >0</span>
                <p>Projects</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div className="count-box">
                <i className="bi bi-headset"></i>
                <span
                  className="supports"
                  ref={countupRefSupports}
                  data-purecounter-start="0"
                  data-purecounter-end="2500"
                  data-purecounter-duration="1"
                >0</span>
                <p>Hours Of Support</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div className="count-box">
                <i className="bi bi-award"></i>
                <span
                  className="awards"
                  ref={countupRefAwards}
                  data-purecounter-start="0"
                  data-purecounter-end="20"
                  data-purecounter-duration="1"
                >0</span>
                <p>Awards</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Facts Section --> */}
    </>
  );
};

export default Facts;
