import axios from "axios";
import { IContactUs } from "../model/request/IContactUs";
import { getLocalBaseUrl } from "../../common/Utility";

function contactUs(contactUs : IContactUs) {
  let url = getLocalBaseUrl()+"/api/notification/email/send";
  return axios.post(url, contactUs);
}

const CvBuilderService = {
    contactUs
  };
  
  export default CvBuilderService;
  