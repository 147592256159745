export enum RouteLinks {
  HOME = "/",

  // Admin Links
  ADMIN = "/admin",
  ADMIN_LOGIN = "/admin/login",
  ADMIN_HOME = "/admin/home",
  ADMIN_PERSONAL = "/admin/personal",
  ADMIN_EDUCATIONAL = "/admin/educational",
  ADMIN_PROFESSIONAL = "/admin/professional",
  ADMIN_ACHIEVEMENTS = "/admin/achievements",
  ADMIN_HOBBIES = "/admin/hobbies"
}
