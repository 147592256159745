import React from "react";
import { Link } from "react-router-dom";
import { RouteLinks } from "../../common/RouteLinks";
import Logout from "./Logout";
import "../assets/css/style.css";
import "bootstrap/dist/js/bootstrap.bundle";

const Header = () => {
  const [path, setPath] = React.useState<string>();

  React.useEffect(() => {
    activeHeader();
  }, []);
  const activeHeader = () => {
    setPath(window.location.pathname);
  };

  return (
    <nav className="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <Link className="navbar-brand" to={RouteLinks.ADMIN_PERSONAL}>
            <img src={require("./../assets/img/logo.png")} alt="Logo" />
            <span>&nbsp; Admin</span>
          </Link>
          <div className="border border-white logo-separator"></div>

          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className={
                  path === RouteLinks.ADMIN_PERSONAL
                    ? "nav-link active"
                    : "nav-link"
                }
                to={RouteLinks.ADMIN_PERSONAL}
              >
                Personal
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  path === RouteLinks.ADMIN_EDUCATIONAL
                    ? "nav-link active"
                    : "nav-link"
                }
                to={RouteLinks.ADMIN_EDUCATIONAL}
              >
                Education
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  path === RouteLinks.ADMIN_PROFESSIONAL
                    ? "nav-link active"
                    : "nav-link"
                }
                to={RouteLinks.ADMIN_PROFESSIONAL}
              >
                Professional
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  path === RouteLinks.ADMIN_ACHIEVEMENTS
                    ? "nav-link active"
                    : "nav-link"
                }
                to={RouteLinks.ADMIN_ACHIEVEMENTS}
              >
                Achievements
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  path === RouteLinks.ADMIN_HOBBIES
                    ? "nav-link active"
                    : "nav-link"
                }
                to={RouteLinks.ADMIN_HOBBIES}
              >
                Hobbies
              </Link>
            </li>
          </ul>
        </div>
        <span className="navbar-text">
          <Logout />
        </span>
      </div>
    </nav>
  );
};

export default Header;
