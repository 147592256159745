import React from "react";
import Hero from "./Hero";

const Header : React.FC = () => {

  React.useEffect(() => {
    // navigation control
    navbarlinksActive();

    window.addEventListener("scroll", navbarlinksActive);
    return () => {
      window.removeEventListener("scroll", navbarlinksActive);
    };
  }, []);

  const navbarlinksActive = () => {
    let navbarlinks =
      document.querySelectorAll<HTMLLinkElement>("#navbar .scrollto");
    let position = window.scrollY + 200;
    navbarlinks.forEach((navbarlink) => {
      let hash = navbarlink.href.split("#")[1];
      // console.log(hash)
      if (!navbarlink.href) return;
      let section = document.querySelector<HTMLElement>("#" + hash);
      if (!section) return;
      // console.log(position)
      if (position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight) {
        navbarlink.classList.add("active");
      } else {
        navbarlink.classList.remove("active");
      }
    });
  };

  const mobileNavToggle = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    let body = document.querySelector("body");
    let target = event.target as HTMLElement;
    if (body && target) {    
      body.classList.toggle("mobile-nav-active");
      target.classList.toggle("bi-list");
      target.classList.toggle("bi-x");
    }
    
    let scrolltos = document.querySelectorAll(".scrollto");
    scrolltos.forEach((el) => {
      el.addEventListener("click",(e) => {
        console.log(e);
        if (body && body.classList.contains('mobile-nav-active')) {
          body.classList.remove('mobile-nav-active')
          target.classList.toggle('bi-list')
          target.classList.toggle('bi-x')
        }
      })
    });
  };

  return (
    <>
      {/* <!-- ======= Mobile nav toggle button ======= --> */}
      {/* <!-- <button type="button" className="mobile-nav-toggle d-xl-none"><i className="bi bi-list mobile-nav-toggle"></i></button> --> */}
      <i className="bi bi-list mobile-nav-toggle d-lg-none" onClick={(event) => mobileNavToggle(event)}></i>
      {/* <!-- ======= Header ======= --> */}
      <header id="header" className="d-flex flex-column justify-content-center">
        <nav id="navbar" className="navbar nav-menu">
          <ul>
            <li>
              <a href="#home" className="nav-link scrollto active">
                <i className="bx bx-home"></i> <span>Home</span>
              </a>
            </li>
            <li>
              <a href="#about" className="nav-link scrollto">
                <i className="bx bx-user"></i> <span>About</span>
              </a>
            </li>
            <li>
              <a href="#resume" className="nav-link scrollto">
                <i className="bx bx-file-blank"></i> <span>Resume</span>
              </a>
            </li>
            <li>
              <a href="#testimonials" className="nav-link scrollto">
                <i className="bx bx-server"></i> <span>Testimonials</span>
              </a>
            </li>
            {/* <li>
              <a href="#blogs" className="nav-link scrollto">
                <i className="bx bx-book-content"></i> <span>Blogs</span>
              </a>
            </li> */}
            <li>
              <a href="#contact" className="nav-link scrollto">
                <i className="bx bx-envelope"></i> <span>Contact</span>
              </a>
            </li>
          </ul>
        </nav>
        {/* <!-- .nav-menu --> */}
      </header>
      {/* <!-- End Header --> */}

      <Hero />
    </>
  );
};

export default Header;
