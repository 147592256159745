import React, { useEffect } from "react";
import Typed from "typed.js";

const Hero : React.FC = () => {
  // typed.js
  useEffect(() => {    
    const typed = new Typed('.typed', {
      strings: ["Backend Developer - Java", "Backend Developer - Springboot", "Backend Developer - Microservice", "Backend Developer - AWS Cloud", "Frontend Developer - ReactJS", "Freelancer"],
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 100,
      smartBackspace: true,
      loop: true,
      showCursor: true
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="home" className="d-flex flex-column justify-content-center">
        <div className="container" data-aos="zoom-in" data-aos-delay="100">
          <h1>Souvik Laha</h1>
          <p>
            I'm <span className="typed" data-typed-items="Designer, Developer, Freelancer"></span>
          </p>
          <div className="social-links">
            {/* LinkedIn */}
            <a href="https://www.linkedin.com/in/souviklaha/" target="_blank" className="linkedin">
              <i className="bx bxl-linkedin"></i>
            </a>

            {/* GitHub */}
            <a href="https://github.com/souviklaha" target="_blank" className="github">
              <i className="bx bxl-github"></i>
            </a>

            {/* Skype */}
            <a href="skype:souviklaha" className="skype">
              <i className="bx bxl-skype"></i>
            </a>
            
            {/* Facebook */}
            <a href="https://www.facebook.com/souviklaha1991/" target="_blank" className="facebook">
              <i className="bx bxl-facebook"></i>
            </a>

            {/* Instagram */}
            {/* <a href="https://www.instagram.com/souviklaha1991/" target="_blank" className="instagram">
              <i className="bx bxl-instagram"></i>
            </a> */}
            
            {/* Twitter */}
            {/* <a href="https://twitter.com/Souvik_Laha" className="twitter">
              <i className="bx bxl-twitter"></i>
            </a> */}
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}
    </>
  );
};

export default Hero;
