import React from "react";
import { RouteLinks } from "../../common/RouteLinks";

const Redirect: React.FC = () => {
  React.useEffect(() => {
    window.location.replace(RouteLinks.ADMIN_LOGIN)
  }, []);
  
  return <></>;
};

export default Redirect;
