import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./web/pages/HomePage";
import Login from "./admin/components/Login";
import Redirect from "./admin/components/Redirect";
import { RouteLinks } from "./common/RouteLinks";
import AdminPersonal from "./admin/pages/AdminPersonal";
import AdminEducational from "./admin/pages/AdminEducational";
import AdminProfessional from "./admin/pages/AdminProfessional";
import AdminAchievements from "./admin/pages/AdminAchievements";
import AdminHobbies from "./admin/pages/AdminHobbies";
import AdminHomePage from "./admin/pages/AdminHomePage";


function App() {
  return (
    <>
      <Routes>
        {/* Web Route */}
        <Route path={RouteLinks.HOME} element={<HomePage />}></Route>

        {/* Admin Routes */}
        <Route path={RouteLinks.ADMIN} element={<Redirect />}></Route>
        <Route path={RouteLinks.ADMIN_LOGIN} element={<Login />}></Route>
        <Route path={RouteLinks.ADMIN_HOME} element={<AdminHomePage />}></Route>
        <Route path={RouteLinks.ADMIN_PERSONAL} element={<AdminPersonal />}></Route>
        <Route path={RouteLinks.ADMIN_EDUCATIONAL} element={<AdminEducational />}></Route>
        <Route path={RouteLinks.ADMIN_PROFESSIONAL} element={<AdminProfessional />}></Route>
        <Route path={RouteLinks.ADMIN_ACHIEVEMENTS} element={<AdminAchievements />}></Route>
        <Route path={RouteLinks.ADMIN_HOBBIES} element={<AdminHobbies />}></Route>
      </Routes>
    </>
  );
}

export default App;
