import React from "react";

const BackToTop: React.FC = () => {
  React.useEffect(() => {
    // back to top control
    backToTop();
    window.addEventListener("scroll", backToTop);
  }, []);

  const backToTop = () => {
    // alert("backToTop")
    let backtotop = document.querySelector(".back-to-top");
    if (backtotop) {
      if (window.scrollY > 100) {
        backtotop.classList.add("active");
      } else {
        backtotop.classList.remove("active");
      }
    }
  };

  return (
    <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
      <i className="bi bi-arrow-up-short"></i>
    </a>
  );
};

export default BackToTop;
