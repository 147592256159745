import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.min.css";
import "../assets/css/style.css";
import { disableBack } from "../../common/Utility";
import Header from "../components/AdminHeader";
import Footer from "../components/AdminFooter";
import ProfessionalDetails from "../components/ProfessionalDetails";

const AdminProfessional: React.FC = () => {
  React.useEffect(() => {
    disableBack();
  }, []);

  return (
    <>
      <Header />
      <main id="main">
      <div className="container-fluid">        
        <ProfessionalDetails />
      </div>
      </main>
      <Footer />
    </>
  );
};

export default AdminProfessional;
