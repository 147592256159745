import React from "react";
// import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Testimonial: React.FC = () => {
  return (
    <>
      {/* <!-- ======= Testimonials Section ======= --> */}
      <section id="testimonials" className="testimonials section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Testimonials</h2>
          </div>          
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
              delay: 5500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            navigation
            pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
          >
            <SwiperSlide>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src={require("./../assets/img/testimonials/testimonial-anindya-ibm.jpeg")}
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Anindya Sarkar</h3>
                  <h4>Solution Architect, Cloud, Big Data at IBM</h4>                  
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Extremely dedicated, passionate learner, technically super sound, honest and 
                    above all a gem of a person - these are the most prominent few qualities I would like to describe 
                    Souvik with. I was the technical team lead of Souvik during his tenure in IBM. 
                    I was very impressed with his technical knowledge and commitment. He provided crucial contribution 
                    in delivering solutions on various new technologies which were being implemented for the first time 
                    in client's business space. E.G, design and implementation on AWS platform, working with 
                    AWS's BigData platform, migration from legacy Perl/Unix based applications to Java/Web based 
                    applications etc. For the sake of the project he learned Perl too and delivered. 
                    He grew well in AWS & Java and acquired certification during that time too. 
                    I respect his attitude towards work and would like to have him in my team every time possible. 
                    I wish him all the best and request him to maintain this spirit for time to come.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>
            {/* <!-- End testimonial item --> */}

            <SwiperSlide>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src={require("./../assets/img/testimonials/testimonial-chandan-tcs.jpeg")}
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Chandan Maity</h3>
                  <h4>AWS certified Solution Architect || Technical Architect || Java || Microservices || Docker || Scrum Lead at Tata Consultancy Services</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    I was impressed by Souvik's ability to handle any situation calmly and patiently, 
                    even with the toughest clients. His expertise as a lead is commendable and it helped our team come up 
                    with more efficient solutions on different projects. His contribution is valuable to the side, 
                    and I highly recommend Souvik and would love to work with him again.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>
            {/* <!-- End testimonial item --> */}

            <SwiperSlide>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src={require("./../assets/img/testimonials/testimonial-surajit-tcs.jpeg")}
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Surajit Kar</h3>
                  <h4>UI/UX Designer and Frontend Developer at Tata Consultancy Services</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Souvik is highly professional, very likable and amazing knowledge he has in his domain of work, 
                    dedicated and hardworking. He played a key role in team and always ready to take ownership whenever required. 
                    He will be a great asset for any organization wherever he go.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>
            {/* <!-- End testimonial item --> */}

            <SwiperSlide>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src={require("./../assets/img/testimonials/testimonial-manodip-clb.jpeg")}
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Manodip Jana</h3>
                  <h4>Senior Software Engineer at MachineSense</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    I had the pleasure of working with Souvik for 9 months at our organization. I was particularly 
                    impressed by his ability to handle various work and we will still miss him in our office. 
                    Any employee would be lucky to have Souvik as a Colleague.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>
            {/* <!-- End testimonial item --> */}

            <SwiperSlide>
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src={require("./../assets/img/testimonials/testimonial-dipanwita-ibm.jpeg")}
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Dipanwita Saha</h3>
                  <h4>Blockchain Developer at IBM</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    souvik is a nice human being, hard worker and very much helpful.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </SwiperSlide>
            {/* <!-- End testimonial item --> */}
          </Swiper>
          {/*<!-- end of swiper --> */}
        </div>
      </section>
      {/* <!-- End Testimonials Section --> */}
    </>
  );
};

export default Testimonial;
