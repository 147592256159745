import React from "react";

const Preloader: React.FC = () => {
  React.useEffect(() => {
    // loading control
    adjustPreloader();
    
  }, []);

  const adjustPreloader = () => {
    // alert('pre')
    let preloader = document.querySelector("#preloader");
    if (preloader) {
      preloader.remove();
    }
  }

  return (
    <>
      <div id="preloader"></div>
    </>
  );
};

export default Preloader;
