import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.min.css";
import "../assets/css/style.css";
import { RouteLinks } from "../../common/RouteLinks";
import { useNavigate } from "react-router-dom";
import { disableBack } from "../../common/Utility";

const Login: React.FC = () => {

  const navigate = useNavigate();

  React.useEffect(() => {
    disableBack();
  }, []);

  const onSubmitEvent = (event:any) => {
    event.preventDefault();
    const formData = {
      emailId: event.target.email.value,
      password: event.target.password.value
    }
    console.log(formData)
    navigate(RouteLinks.ADMIN_HOME);
  };

  const onCancelEvent = () => {
    window.location.replace(RouteLinks.HOME)
  }

  return (
    <div className="container">
      <div className="row align-items-center login-row">        
        <div className="mx-auto col-10 col-md-8 col-lg-6">
          <div className="card">
            <div className="card-body">
              <h1 className="text-center">Login</h1>
              <form onSubmit={onSubmitEvent}>
                <div className="form-group mt-2">
                  <label className="form-label" htmlFor="email">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  />
                </div>
                <div className="form-group mt-2">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Password"
                  />
                </div>
                <div className="form-group mt-2">
                  <button type="submit" className="btn btn-primary float-start">
                    Submit
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary float-end"
                    onClick={onCancelEvent}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
