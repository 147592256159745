import React from "react";

const Footer : React.FC = () => {
  return (
    <>
      {/* <!-- ======= Footer ======= --> */}
      <footer id="footer">
        <div className="container">
          <h3>Souvik Laha</h3>
          <p>
            Innovative | Dedicated | Quick Learner | Customer Focused | Deadline Oriented | Perfectionist
          </p>
          <div className="social-links">
            {/* LinkedIn */}
            <a href="https://www.linkedin.com/in/souviklaha/" target="_blank" className="linkedin">
              <i className="bx bxl-linkedin"></i>
            </a>

            {/* GitHub */}
            <a href="https://github.com/souviklaha" target="_blank" className="github">
              <i className="bx bxl-github"></i>
            </a>

            {/* Skype */}
            <a href="skype:souviklaha" className="skype">
              <i className="bx bxl-skype"></i>
            </a>

            {/* Facebook */}
            <a href="https://www.facebook.com/souviklaha1991/" target="_blank" className="facebook">
              <i className="bx bxl-facebook"></i>
            </a>        
            
            {/* <a href="https://www.instagram.com/souviklaha1991/" target="_blank" className="instagram">
              <i className="bx bxl-instagram"></i>
            </a>
            
            <a href="https://twitter.com/Souvik_Laha" className="twitter">
              <i className="bx bxl-twitter"></i>
            </a> */}
          </div>
          <div className="copyright">
            &copy; Copyright <strong><span>Souvik Laha</span></strong>. All Rights Reserved
          </div>
          <div className="credits">            
            Designed by <a href="http://www.nexverse.co.in">Nexverse</a>
          </div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}
            
    </>
  );
};

export default Footer;
