import React from "react";
import Facts from "./Facts";
import Skils from "./Skils";

const About : React.FC = () => {
  return (
    <>
      {/* <!-- ======= About Section ======= --> */}
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>About</h2>
            <p>
              With 9+ years of experience, I have knowledge in Core Java, Advance Java, Microservice and 
              AWS Cloud Application development also. I am a certified Java developer and also certified AWS developer.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <img
                src={require("./../assets/img/my-profile-pic.jpg")}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-8 pt-4 pt-lg-0 content">
              <h3>Java Fullstack Application Developer</h3>
              <p className="fst-italic">
                Java | Springboot | Microservice | RESTful APIs | AWS Cloud | React JS | Big-Data
              </p>
              <div className="row">
                <div className="col-lg-6">
                  <ul>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Birthday:</strong> <span>03 May 1991</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Website:</strong> <span>www.souviklaha.me</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Phone:</strong> <span>+44 7469 4382 00</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>City:</strong> <span>Edinburgh, UK</span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Age:</strong> <span>32</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Degree:</strong> <span>Bachelor</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Email:</strong>{" "}
                      <span>lahasouvik@gmail.com</span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Freelance:</strong> <span>Available</span>
                    </li>
                  </ul>
                </div>
              </div>
              <p>
                Extensive experience working in an Agile development environment. 
                Profound knowledge of Java/J2EE, web services and development of service oriented applications. 
                Sound knowledge of understanding of object oriented design and programming. 
                Excellent ability to develop advanced concepts, techniques and standards.
                During my 9+ years of experience, I have worked in all aspects of Software Development, starting from 
                requirement analysis, system design, coding, writing JUnit test cases, validating the test cases written by QA, 
                system's performance improvement, system support and last but not the least documenting the technical 
                implementations and features.                
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Section --> */}
      <Facts />
      <Skils />
    </>
  );
};

export default About;
