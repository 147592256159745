import React from "react";
import { Waypoint } from "react-waypoint";

const Skils: React.FC = () => {
  const skillAnim = () => {
    let skillsContent = document.querySelector(".skills-content");
    if (skillsContent) {
      let progress = document.querySelectorAll<HTMLElement>(".progress .progress-bar");
      progress.forEach((el) => {
        el.style.width = el.getAttribute('aria-valuenow') + '%'
      });
    }
  }
  return (
    <>
      {/* <!-- ======= Skills Section ======= --> */}
      <section id="skills" className="skills section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Skills</h2>
            <p>
              I have worked in various technologies and tools throughout the tenure of work till date. 
              A glimpse of skills are given below. 
            </p>
          </div>
          <Waypoint onEnter={skillAnim}></Waypoint>
          <div className="row skills-content">
            <div className="col-lg-6">
              {/* <!-- Java --> */}
              <div className="progress">
                <span className="skill">
                  Java <i className="val">92%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={92}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              {/* <!-- Springboot --> */}
              <div className="progress">
                <span className="skill">
                  Springboot <i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  RESTful API <i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              {/* <!-- Microservice --> */}
              <div className="progress">
                <span className="skill">
                  Microservice <i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              {/* <!-- AWS Cloud --> */}
              <div className="progress">
                <span className="skill">
                  AWS Cloud <i className="val">85%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={85}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              {/* <!-- Docker --> */}
              <div className="progress">
                <span className="skill">
                  Docker <i className="val">77%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={77}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              {/* <!-- Frontend --> */}
              <div className="progress">
                <span className="skill">
                  Frontend <i className="val">80%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={80}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              {/* <!-- ReactJS --> */}
              <div className="progress">
                <span className="skill">
                  React JS <i className="val">65%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={65}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              {/* <!-- RabbitMQ --> */}
              <div className="progress">
                <span className="skill">
                  Rabbit MQ <i className="val">68%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={68}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              {/* <!-- Big-Data --> */}
              <div className="progress">
                <span className="skill">
                  Big-Data, Hadoop, Map-Reduce <i className="val">65%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={65}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              {/* <!-- Kafka --> */}
              <div className="progress">
                <span className="skill">
                  Kafka <i className="val">50%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>

              {/* <!-- Kubernetes --> */}
              <div className="progress">
                <span className="skill">
                  Kubernetes <i className="val">40%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Skills Section --> */}
    </>
  );
};

export default Skils;
