import React from 'react'

const Resume = () => {
  return (
    <>
    {/* <!-- ======= Resume Section ======= --> */}
    <section id="resume" className="resume">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Resume</h2>
          <p>
            Passionate agile developer with over nine years of experience in software development. 
            Worked on a wide range of software applications, each of which exceeded customer expectations, 
            all while remaining ahead of schedule.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <h3 className="resume-title">Summary</h3>
            <div className="resume-item pb-0">
              <h4>Souvik Laha</h4>
              <p>
                <em>Dedicated, Client-focused, Deadline-oriented, Innovative and Smart working Professional 
                with 9+ years of experience in developing user-centered digital application from initial concept to final, 
                polished deliverable.</em>
              </p>
              <ul>
                <li>Edinburgh, Scotland, United Kingdom</li>
                <li>+44 7469 4382 00</li>
                <li>lahasouvik@gmail.com</li>
              </ul>
            </div>

            <h3 className="resume-title">Education</h3>
            <div className="resume-item">
              {/* B.Tech */}
              <h4>Bachelor of Technology</h4>
              <h5>2009 - 2013</h5>
              <p><em>West Bengal University of Technology, Kolkata, West Bengal, India</em></p>
              <p>Completed 4 years Bachelor degree in Information Technology.</p>
              <p>CGPA: 8.07/10</p>
            </div>
            {/* High School (10+2) */}
            <div className="resume-item">
              <h4>High School (10 + 2 Standard)</h4>
              <h5>2009</h5>
              <p><em>Hindu School, Kolkata, West Bengal, India</em></p>
              <p>Completed High School education with Physics, Chemistry, Biology &amp; Mathematics.</p>
              <p>Percentage: 73.71%</p>
            </div>
            {/* Secondary School */}
            <div className="resume-item">
              <h4>High School (10<sup>th</sup> Standard)</h4>
              <h5>2007</h5>
              <p><em>Hindu School, Kolkata, West Bengal, India</em></p>
              <p>Completed Secondary School with general subjects.</p>
              <p>Percentage: 86.63%</p>
            </div>
          </div>
          <div className="col-lg-6">
            <h3 className="resume-title">Professional Experience</h3>
            {/* JP Morgan */}
            <div className="resume-item">
              <h4>Associate Software Engineer - III</h4>
              <h5>August, 2022 - Present</h5>
              <p><em>J.P.Morgan Chase, Glasgow, United Kingdom </em></p>
              <ul>
                {/* <li>Lead in the development, and implementation of the application from scratch.</li> */}
                <li>Involved in technical designing of the application.</li>
                <li>Development in Java, Spring-Boot, Microservice and AWS Cloud.</li>
                <li>Involved in DevOps activity.</li>
                <li>Analyzing the requirements.</li>
                <li>Mentoring team members.</li>
              </ul>
            </div>
            {/* Apexon */}
            <div className="resume-item">
              <h4>Senior Software Engineer</h4>
              <h5>July, 2022 - July, 2023</h5>
              <p><em>Apexon, Edinburgh, United Kingdom </em></p>
              <ul>
                <li>Lead in the development, and implementation of the application from scratch.</li>
                <li>Involved in technical designing of the application.</li>
                <li>Development in Java, Spring-Boot, Microservice and RabbitMQ.</li>
                <li>Implement Serverless architecture in AWS Cloud.</li>
                <li>Analyzing the requirements.</li>
                <li>Mentoring graduates.</li>
              </ul>
            </div>
            {/* TCS */}
            <div className="resume-item">
              <h4>Assistant Consultant</h4>
              <h5>October, 2018 - June, 2022</h5>
              <p><em>Tata Consultancy Services, Kolkata, India</em></p>
              <ul>                
                <li>Lead Teams in Tata Labs and in other projects also.</li>
                <li>Worked as Agile Pod Lead & onsite coordinator.</li>
                <li>Develop application based on Java, Vert.X, Spring-boot &amp; RESTful APIs.</li>
                <li>Developed CI/CD pipeline &amp; nvolved in DevOps works in AWS environment.</li>
                <li>Involved in AWS Lambda service implementation.</li>
                {/* <li>Dealing with customers regarding technical task.</li>
                <li>Performance improvement &amp; defect fixing within tight SLA.</li>
                <li>Writing the unit test cases using Mockito.</li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-12 text-center">
            <p>
              To know more about professional experiences, projects, tasks, roles, responsibilities &amp; awards, 
              please download the updated resume.
            </p>
            <a href={require("./../assets/resume/Resume_Souvik_Laha.pdf")} className="btn btn-primary" target='_blank'>Download Resume</a>
          </div>
        </div>
      </div>
    </section>
    {/* <!-- End Resume Section --> */}
    </>
  )
}

export default Resume